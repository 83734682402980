<template>
  <div class="DAV-workshop__trunk">
    <component-title color="green" @goPrev="goPrev" />
    <div
      class="DAV-blockClass"
      :class="{
        'DAV-blockClass--option-mode': this.$route.path === '/option'
      }"
    >
      <div class="multiple-img-wrap">
        <div class="frmBlock__title">이미지 업로드</div>
        <div class="frmBlock__layout">
          <ImagePicker
            ref="imagePicker"
            :index="getPopup.index"
            :value="getPopup.value.img"
            :option="getPopup.option"
          />
        </div>
        <div class="frmBlock__title frmBlock__title--last">
          이미지 설명(alt)
        </div>
        <div class="frmBlock__layout">
          <TextField
            placeholder="이미지 설명을 입력해주세요."
            v-model="text"
            @input="changeInput"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";
const ModuleEditor = createNamespacedHelpers("ModuleEditor");

export default {
  data() {
    return {
      text: ""
    };
  },
  computed: {
    ...ModuleEditor.mapGetters(["getPopup"])
  },
  mounted() {
    this.text = this.getPopup.value.alt;
  },
  methods: {
    // 이전 리스트로 이동
    goPrev() {
      this.$store.commit("ModuleEditor/setPopup", {
        page: null
      });
    },
    // change Input
    changeInput(v) {
      let id =
        this.$route.path === "/option"
          ? this.getPopup.option.componentOptionId
          : this.getPopup.option.id;

      this.$EventBus.$emit(`MultiImageUpdateText${id}`, v, this.getPopup.index);
    }
  },
  components: {
    ComponentTitle: () => import("@/components/editor/ComponentTitle.vue")
  }
};
</script>

<style lang="scss" scoped>
.multiple-img-wrap {
  background: #fff;
  padding: 24px 16px;
}

/* 반응형 - desktop */
@media screen and (min-width: 1024px) {
  .DAV-workshop__heading {
    h3 {
      padding-left: 32px;
    }
  }
}
/* 반응형 - mobile */
@media screen and (max-width: 1023px) {
  .DAV-blockClass {
    &--option-mode {
      top: 57px;
    }
  }
  .DAV-workshop__heading {
    display: none !important;
    &--option-mode {
      display: block !important;
    }
  }
}
</style>
