var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-workshop__trunk"
  }, [_c('component-title', {
    attrs: {
      "color": "green"
    },
    on: {
      "goPrev": _vm.goPrev
    }
  }), _c('div', {
    staticClass: "DAV-blockClass",
    class: {
      'DAV-blockClass--option-mode': this.$route.path === '/option'
    }
  }, [_c('div', {
    staticClass: "multiple-img-wrap"
  }, [_c('div', {
    staticClass: "frmBlock__title"
  }, [_vm._v("이미지 업로드")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('ImagePicker', {
    ref: "imagePicker",
    attrs: {
      "index": _vm.getPopup.index,
      "value": _vm.getPopup.value.img,
      "option": _vm.getPopup.option
    }
  })], 1), _c('div', {
    staticClass: "frmBlock__title frmBlock__title--last"
  }, [_vm._v(" 이미지 설명(alt) ")]), _c('div', {
    staticClass: "frmBlock__layout"
  }, [_c('TextField', {
    attrs: {
      "placeholder": "이미지 설명을 입력해주세요."
    },
    on: {
      "input": _vm.changeInput
    },
    model: {
      value: _vm.text,
      callback: function callback($$v) {
        _vm.text = $$v;
      },
      expression: "text"
    }
  })], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }